@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}





html {
  scrollbar-color: "#123555" white;
  
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
  
  background-color: #0f2a43;
}

/* chrome */
::-webkit-scrollbar-thumb {
  background: #6d7985;
  
}
  

/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/inter-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/inter-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/inter-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/inter-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/inter-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/inter-v12-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-800 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/inter-v12-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/inter-v12-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/inter-v12-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/inter-v12-latin-800.woff') format('woff'), /* Modern Browsers */
       url('./fonts/inter-v12-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/inter-v12-latin-800.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-900 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/inter-v12-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/inter-v12-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/inter-v12-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/inter-v12-latin-900.woff') format('woff'), /* Modern Browsers */
       url('./fonts/inter-v12-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/inter-v12-latin-900.svg#Inter') format('svg'); /* Legacy iOS */
}
